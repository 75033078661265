/* Animacja zmieniająca tło i przybliżenie */
@keyframes zoomAndChangeBackground {
    0% {
        background-image: url('../../assets/images/SZYM_ogrody_001.jpg');
        background-size: cover; /* Utrzymanie proporcji obrazu */
        background-position: center;
    }
    33% {
        background-image: url('../../assets/images/SZYM_parking_001.jpg');
        background-size: cover; /* Utrzymanie proporcji obrazu */
        background-position: center;
    }
    66% {
        background-image: url('../../assets/images/SZYM_taras_001.jpg');
        background-size: cover; /* Utrzymanie proporcji obrazu */
        background-position: center;
    }
    100% {
        background-image: url('../../assets/images/SZYM_ogrody_001.jpg');
        background-size: cover; /* Utrzymanie proporcji obrazu */
        background-position: center;
    }
}

/* Klasa dla kontenera */
.hero-background {
    background-image: url('../../assets/images/SZYM_ogrody_001.jpg');
    background-position: center;
    background-attachment: scroll; /* Zmieniono na scroll dla mobilnych */
    background-size: cover; /* Rozciąganie tła, ale z zachowaniem proporcji */
    animation: zoomAndChangeBackground 15s ease-in-out infinite;
}

/* Dostosowanie do urządzeń mobilnych */
@media (max-width: 768px) {
    .hero-background {
        background-attachment: scroll;  /* Zmiana tła na scroll */
        background-size: cover; /* Tło wypełnia kontener, zachowując proporcje */
    }
}

/* Dostosowanie dla większych ekranów */
@media (min-width: 768px) {
    .hero-background {
        background-attachment: fixed; /* Tło się nie przewija na desktopach */
        background-size: cover; /* Tło wypełnia kontener */
    }
}
